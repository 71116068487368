
import { Component, Vue } from "vue-property-decorator";
import { FamilyService, UserService } from "src/services";
import EnumUtils from "src/utils/enum-utils";
import { RELATION } from "src/enums";
import { Toast,Dialog } from "vant";
import globalConfig from "src/config/index";

@Component
export default class OfteUser extends Vue
{
    /**
     * 家属数据列表
     * @private
     * @returns any
     */
    private familyList: Array<any> = [];

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    protected get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];
        return employerConfig;
    }

    /**
     * 获取与本人关系
     * @private
     * @returns string
     */
    private getRelationship(value: any): string
    {
        return EnumUtils.getEntry(value, RELATION) && EnumUtils.getEntry(value, RELATION).description;
    }

    /**
     * 跳转修改体检用户家属数据列表
     * @private
     * @returns void
     */
    private updataFamilyInfo(item): void
    {
        this.$router.push({name: "family-operation",query: {userId: item.employerMemberId}});
    }
    

    /**
     * 获取体检用户家属数据列表
     * @private
     * @returns void
     */
    private async getFamilyList(): Promise<void>
    {
        try
        {
            let {content: result} =  await FamilyService.instance.getFamilyList();
            if(result.data)
            {
                this.familyList = result.data;
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 校验是否可以删除此用户
     * @private
     * @returns void
     */
    private async onCheckDelete(id: any): Promise<void>
    {
        try
        {
            let {content: result} =  await UserService.instance.checkDeleteFamily(id);
            if (result.data)    // 可以删除
            {
                Dialog.confirm({
                    title: '确定删除此家属？',
                    message: '家属身份删除后，所有数据将无法恢复，请谨慎操作',
                    confirmButtonColor: "#00D5C1"
                    })
                    .then(() => {
                        this.onDelete(id);
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
            else
            {
                Dialog.alert({
                    message: '此家属存在未完成的订单，暂不能删除',
                    closeOnPopstate: false,
                    confirmButtonColor: "#00D5C1"
                }).then(() => {
                // on close
                });
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 删除此用户
     * @private
     * @returns void
     */
    private async onDelete(id: any): Promise<void>
    {
        try
        {
            await UserService.instance.deleteFamily(id);
            Toast("删除成功");
            this.getFamilyList();

        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        this.getFamilyList();
    }

}
